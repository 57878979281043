import Entete from "./English/A. Entete/Entete";
import Corps from "./English/B. Corps/Corps";

const English = () => {
    return (
        <>
            <Entete />
            <Corps />
        </>
    )
}

export default English;