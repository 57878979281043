import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Pied = () => {
    return (
        <Wrapper>ABCDEF</Wrapper>
    )
}

const Wrapper = styled.footer``

export default Pied;