import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Tete = () => {
    return (
        <Wrapper>ABCDEF</Wrapper>
    )
}

const Wrapper = styled.header``

export default Tete;