import Entete from "./Deutsch/A. Entete/Entete";
import Corps from "./Deutsch/B. Corps/Corps";

const Deutsch = () => {
    return (
        <>
            <Entete />
            <Corps />
        </>
    )
}

export default Deutsch;