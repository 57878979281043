import Entete from "../1.Entete/Entete";
import Corps from "../2.Corps/Corps";
import Pied from "../3.Pied/Pied";

const Francais = () => {
    return (
        <>
            <Entete />
            <Corps />
            <Pied />
        </>
    )
}

export default Francais;